import * as React from 'react'

declare global {
  interface Window {
    Trustpilot: any
  }
}

interface Props {
  locale: string
  templateId: string
  businessUnitId: string
}

export const TrustPilotWidget: React.FC<Props> = ({
  templateId,
  businessUnitId,
  locale,
}): JSX.Element => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref}
      className="trustpilot__widget"
      data-locale={locale}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-schema-type="Organization"
    >
      <a
        href="https://dk.trustpilot.com/review/www.smarteyes.dk"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        Trustpilot
      </a>
    </div>
  )
}
