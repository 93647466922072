import * as React from 'react'

import { withErrorPage } from '@grandvisionhq/next'
import { compose } from '@grandvisionhq/utils'
import { Page, Props } from '@grandvisionhq/www-next/pages/container'

import { withCms, withLayout } from '../../cms'
import { defaultErrorProps } from '../../components/error-props'
import { TrustPilotWidget } from '../../components/widgets/trustpilot-widget'
import { INTL_DEFAULTS } from '../../configuration/intl'

const defaultLocale = INTL_DEFAULTS.locale

function ContainerPage(props: Props) {
  return (
    <Page
      {...props}
      footer={
        <div className="site-container trustpilot__site-container">
          <TrustPilotWidget
            templateId="53aa8912dec7e10d38f59f36"
            businessUnitId="522b46c9000064000576a4c9"
            locale={defaultLocale}
          />
        </div>
      }
    />
  )
}

ContainerPage.getInitialProps = Page.getInitialProps

export default compose<Props, Props>(
  withCms(),
  withErrorPage(defaultErrorProps),
  withLayout()
)(ContainerPage)
